import React, { Component } from "react";

class Settings extends Component {
  state = {};
  render() {
    return <h1 className="section-title">تنظیمات</h1>;
  }
}

export default Settings;
