import React from "react";
import EditSMSServicePhoneNumberForm from "./editSMSServicePhoneNumberForm";

const SMSServiceSettings = () => {
  return (
    <div>
      <EditSMSServicePhoneNumberForm />
    </div>
  );
};

export default SMSServiceSettings;
