import React from "react";
import EditStatisticsSettingsForm from "./editStatisticsSettingsForm";

const StatisticsSettings = () => {
  return (
    <div>
      <EditStatisticsSettingsForm />
    </div>
  );
};

export default StatisticsSettings;
